import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAscSortOrder, groupByMultiple, upperCase } from ".";

const PsSelect = (props) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    setSelectedValue(props.value || null);
  }, [props.value]);

  const onChange = (value) => {
    setSelectedValue(value);
    if (props.onChange) {
      let m = props.dataSource.find(
        (item) => item[props.valueField || "id"] == value
      );
      props.onChange(value, m);
    }
  };

  useEffect(() => {
    if (searchValue != null) {
      const timeoutId = setTimeout(() => callPropsFuc(), 900);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);

  const callPropsFuc = () => {
    if (props.onSearch) {
      props.onSearch(searchValue);
      setSearchValue(null);
    }
  };

  const onSearch = (value) => {
    if (props.ajaxSearch) setSearchValue(value);
    else if (props.onSearch) props.onSearch(value);
  };

  const labelValue = (item) => {
    let lableField = props.lableField;
    if (lableField && typeof lableField == "function") {
      return props.lableField(item);
    } else {
      return lableField && item[lableField] ? item[lableField] : item.name;
    }
  };

  const options = () => {
    if (props.options) return props.options;
    let rv = [];
    if (props.withNull)
      rv.push({ value: 0, label: props.withNullValue || "None" });
    if (props.dataSource) {
      if (props.groupBy) {
        let ds = groupByMultiple(
          props.dataSource.sort(getAscSortOrder("row_type")),
          function (obj) {
            return [obj[props.groupBy]];
          }
        );
        ds.map((d, j) => {
          let opt = [];
          d.sort(getAscSortOrder("bank_name")).map((item, i) => {
            opt.push({
              value:
                props.valueField && item[props.valueField]
                  ? item[props.valueField]
                  : item.id,
              label: labelValue(item),
            });
          });
          rv.push({
            label: upperCase(d[0][props.groupBy]),
            options: opt,
          });
        });
      } else {
        props.dataSource.map((item, i) => {
          rv.push({
            value:
              props.valueField && item[props.valueField]
                ? item[props.valueField]
                : item.id,
            label: labelValue(item),
          });
        });
      }
    }
    return rv;
  };

  return (
    <>
      {props.inputName && (
        <input type="hidden" name={props.inputName} value={selectedValue} />
      )}
      <Select
        showSearch
        placeholder={props.placeholder || "-Select-"}
        optionFilterProp="children"
        size={props.size || "small"}
        style={{ width: props.width || "100%" }}
        value={selectedValue}
        allowClear={true}
        mode={props.multiple && "multiple"}
        className={props.className}
        filterOption={
          props.onSearch
            ? false
            : (input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
        }
        onChange={onChange}
        onSearch={onSearch}
        options={options()}
        dropdownRender={props.dropdownRender || null}
      />
    </>
  );
};

export default PsSelect;
