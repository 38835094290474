import React, { Suspense } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import ForgotPassword from "../pages/forgotPassword";
import StudentGrivanceForm from "../pages/iFramesForms/studentGrivanceForm";
import ContactForm from "../pages/iFramesForms/contactForm";
import EventRegistration from "../pages/iFramesForms/eventRegistration";

const AppPage = React.lazy(() => import("../pages/app"));
const Login = React.lazy(() => import("../pages/login"));
const LoginV2Username = React.lazy(() =>
  import("../pages/loginV2/loginV2Username")
);
const LoginV2Password = React.lazy(() =>
  import("../pages/loginV2/loginV2Password")
);
const LoginType = React.lazy(() => import("../pages/login/loginType"));
const Layout = React.lazy(() => import("../pages/app/layout"));

const Application = React.lazy(() => import("../pages/application/index"));
const Grievance = React.lazy(() => import("../pages/grievance/index"));

const AdmissionEnquiry = React.lazy(() =>
  import("../pages/applicationEnquiry/index")
);
const PaymentStatus = React.lazy(() =>
  import("../pages/applicationEnquiry/paymentStatus/index")
);
const Alumni = React.lazy(() => import("../pages/alumni/index"));
const Opac = React.lazy(() => import("../pages/opac/index"));
const ReaderCorner = React.lazy(() => import ("../pages/readercorner/index"));
const Helpcenter = React.lazy(() => import("../pages/helpCenter/index"));
const SearchResults = React.lazy(() =>
  import("../pages/helpCenter/searchResults")
);
const ViewTopics = React.lazy(() => import("../pages/helpCenter/viewTopics"));
const EditTopic = React.lazy(() => import("../pages/helpCenter/editTopic"));
const RegisterOthers = React.lazy(() =>
  import("../pages/alumni/registerOthers2")
);

export default () => (
  <HashRouter>
    <Suspense
      fallback={
        <div className="text-center" style={{ marginTop: "calc(30vh)" }}>
          <Spinner animation="border" />
        </div>
      }
    >
      <Switch>
        <Route path="/application" component={Application} />
        <Route path="/grievance" component={Grievance} />
        <Route path="/admission-enquiry" component={AdmissionEnquiry} />
        <Route path="/payment-status" component={PaymentStatus} />
        <Route path="/alumni" component={Alumni} />
        <Route path="/helpcenter" component={Helpcenter} />
        <Route path="/opac" component={Opac} />
        <Route path="/readercorner" component={ReaderCorner} />

        <Route path="/searchresults" component={SearchResults} />
        <Route
          path="/viewtopics/:contentId/:parentId/:title/:body"
          component={ViewTopics}
        />
        <Route path="/editTopic" component={EditTopic} />
        <Route path="/registerOthers" component={RegisterOthers} />

        <Route
          path="/iforms/student-grievance"
          component={StudentGrivanceForm}
        />
        <Route
          path="/iforms/event-registration"
          component={EventRegistration}
        />
        <Route path="/iforms/contact-form" component={ContactForm} />

        <Route path="/app" component={Layout} />

        <Route path="/login" component={LoginV2Password} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/" component={LoginType} />
      </Switch>
    </Suspense>
  </HashRouter>
);
